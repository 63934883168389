.panel {
  display: flex;
  flex-direction: column;
  background-color: #e5faff;
  border: 0.15vw solid transparent;
  border-radius: 0.3vw;
  text-align: justify;
  min-width: 20vw;
  overflow-y: scroll;
}

/* .tutorialWrapper{
  position: relative;
  padding: .7vw;
  background-color: #FFF;
  border: 0.1vw solid transparent;
  border-radius: 0.3vw;
} */

.content {
  margin: 1.5vw;
}

.content h3 {
  color: #55565a;
  font-size: 1.5vw;
  margin-top: 0;
  margin-left: .3vw;
}

.content hr {
  height: 1px;
  border: 0;
  border-top: 0.15vw solid #55565a;
  margin: 1em 0;
  max-width: 100%;
  text-align: center;
  align-self: center;
}

.user {
  display: flex;
  align-items: center;
  margin-top: 2.8vw;
  margin-bottom: 0.5em;
}

.user p {
  margin-left: 0.5rem;
}

.adminName {
  /* font-size: xx-large; */
  font-size: 1.3vw;
  font-weight: 700;
  color: #55565a;
  margin-right: 0;
}

.adminText {
  font-size: 0.8vw;
  font-weight: 700;
  color: #FF9E22;
  border: 0.15vw solid #FF9E22;
  border-radius: .3vw;
  padding: 0 .2vw;
}

.adminBuilder {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inviteText {
  margin: 0;
  font-size: 1.1vw;
  font-weight: 700;
  text-align: center;
  color: #55565a;
}

.notAdmin {
  margin-right: 0 !important;
  font-size: 1.3vw;
  font-weight: 700;
  color: #55565a;
}

.dot {
  height: 2.1vw;
  width: 2.2vw;
  /* background-color: #f89b38; */
  border-radius: 50%;
  display: inline-block;
  color: white;
  text-align: center;
  text-transform: capitalize;
  font-size: 1.6vw;
}

.allPanels {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.5vw;
  margin-top: 1.5vw;
}

.half {
  display: flex;
  flex-direction: column;
  padding-left: 1.5vw;
}

.tutorialPanel {
  background-color: #E5FAFF;
  border: .15vw solid transparent;
  border-radius: .3vw;
  text-align: justify;
  font-weight: 400;
  font-size: 1.05vw;
  line-height: 1.05vw;
  width: 55vw;
}

.tutorialText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 40px;
  margin-top: 20px;
  color: #55565A;
}

.tutorialText h3 {
  font-size: 1.35vw;
  margin: 0;
  margin-bottom: 2.2vw;
}

.centerTutorial {
  display: flex;
  flex-direction: row;
}

.centerTutorial ol {
  margin: 0;
  padding: 1.5vw;
  padding-left: 1.2vw;
  padding-bottom: 0;
  font-weight: 600;
}

.centerTutorial ol li {
  margin-bottom:.5vw;
  font-weight: 500;
}

.centerTutorial ol li::marker {
  color: #55565A !important;
  font-weight: 500;
}

.imageDiv{
  display: flex;
  flex-direction: column;
  /* margin-bottom: 10vw; */
}

.imageSection{
  width: 17vw;
  height: 17vw;
  cursor: pointer;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  border: 0.1vw solid #0096d6;
  border-radius: 0.4vw;
  background-color: #FFF;
}

.imageSection img {
  height: 10vw;
}

.inputDiv{
  display: flex;
  margin-top: 1vw;
  justify-content: space-between;
}

.timer {
  margin: 0;
  padding: .3vw;
  background-color: #BFD1BE;
  border-radius: .2vw;
  color: black;
  text-align: center;
  font-weight: 500;
  font-size: 1.05vw;
  line-height: 30px;
}

.orderedList{
  padding: 2vw;
  padding-top: 0;
}

.orderedList p{
  margin: 0;
  white-space: nowrap;
  font-weight: 700;
}

.centerTutorial ul {
  font-weight: 400;
  word-spacing: .2vw;
  font-size: 1.2vw;
  line-height: 1.3vw;
  margin: 0;
  padding: 0;
  padding-right: 2vw;
  text-align: left;
  list-style: none;
  align-self: center;
}

.centerTutorial ul hr{
  width: 15%;
  margin-top: 2vw;
}

.centerTutorial ul li {
  padding-top: 1vw;
}

/* .centerTutorial img {
  width: 17vw;
  height: 17vw;
  border: .1vw solid #0096D6;
  border-radius: .4vw;
} */

.input {
  background-color: white;
  border: .12vw solid #0096D6;
  color: #999999;
  font-weight: 400;
  font-size: .73vw;
  padding: 0.5vw 0.5vw;
  border-radius: .3vw;
  min-width: 68%;
}

.startButton {
  cursor: pointer;
  color: white;
  background-color: #0096D6;
  border: .1vw solid transparent;
  border-radius: .4vw;
  padding: 0.5rem 1.5rem;
  font-size: 1vw;
  margin: 1.2vw;
  margin-top: 0;
}

.startButtonInnactive {
  cursor: not-allowed;
  color: #E5FAFF;
  background-color: #99D5EF;
  border: .1vw solid transparent;
  border-radius: .4vw;
  /* padding: 0.5rem 1.5rem; */
  padding: 0.5vw 1.3vw;
  font-size: 1vw;
  margin: 1.2vw;
  margin-top: 0;
}

.closeButton {
  cursor: pointer;
  background-color: transparent;
  color: #0096D6;
  border: .15vw solid #0096D6;
  border-radius: .4vw;
  /* padding: 0.5rem 1.3rem; */
  padding: 0.5vw 1.3vw;
  font-size: 1vw;
  font-weight: bolder;
  line-height: 18px;
  margin: 1.2vw;
  margin-top: 0;
}

.title section {
  display: flex;
  align-items: baseline;
  color: #55565A;
  margin-bottom: 2.5vw;
}

.title h2{
  margin: 0;
  font-weight: 400;
  font-size: 2vw;
}

.title span {
  display: flex;
  border: .12vw solid #0096D6;
  cursor: pointer;
  border-radius: .3vw;
  margin-left: 1.3vw;
  padding: .3vw 1vw;
}

.copyAlert{
  display: none;
  position: absolute;
  color: #FF9E22;
  font-weight: 700;
  margin: 0;
  margin-left: 3vw;
  font-size: .8vw;
  text-align: center;
}

.title img {
  width: 1.5vw;
  margin-left: .5vw;
}

.title h1{
  font-size: 2vw;
  margin: 0;
  margin-right: 1vw;
}


.tooltip {
  display: none;
  color: white;
  position:absolute;
  border: 0 solid transparent;
  border-radius: .1vw;
  background-color: #9FA3BB;
  font-size: .7vw;
  font-weight: 550;
  margin: 0;
  padding: 0.1vw 0.2vw;
  max-width: 6vw;
  margin-top: 2.5vw;
}

.startGameDiv{
  display: flex;
  justify-content: center;
}

.innactiveGameDiv:hover .tooltip{
  display: block;
}

.backdrop {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #404778;
  opacity: 0.5;
}

.tutorialWrapper{
  position: relative;
  padding: .7vw;
  background-color: #FFF;
  border: 0.1vw solid transparent;
  border-radius: 0.3vw;
}

.imageTutorialWrapper {
  position: relative;
  padding: .7vw;
  background-color: #FFF;
  border: 0.1vw solid transparent;
  border-radius: 0.3vw;
}

.tutorialWrapper div {
  margin: 0 !important;
}
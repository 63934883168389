.card {
  display: flex;
  flex-direction: column;
  background-color: #E5FAFF;
  border: .1vw solid transparent;
  border-radius: .4vw;
  text-align: justify;
  height: auto;
  overflow-y: auto;
  /* width: 23vw; */
  margin: 1vw;
  margin-top: 0vw;
}

.card hr {
  max-width: 100%;
}

.cardHeader {
  display: flex;
  align-items: center;
  margin-top: .3em;
  margin-bottom: .5em;
  font-size: 1vw;
  color: #55565A;
}

.striked {
  color: #FFCE90;
  text-decoration-line: line-through;
}

.striked::before{
  color: #FFCE90 !important;
}

.voting{
  font-weight: bolder;
  color: black !important;
}

.voting::before{
  color: black !important;
}

.valid{
  color:#0096D6;
}

.valid::before{
  color:#0096D6 !important;
}

.points {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  justify-content: flex-end;
}

.first {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 0.3vw;
}

.covered{
  background-color: #9FA3BB;
  color: transparent;
  border-radius: .2vw;
  width: fit-content;
  user-select:none;
}

::marker{
  color: black;
}

.second {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* color: #838383; */
}

.userBuilder h1 {
  font-size: larger;
  font-weight: 700;
  margin: 0;
}

.userBuilder p {
  margin: 0;
  font-weight: 700;
  font-size: .8vw;
  margin-left: .5rem;
  color: #FF9E22;
  border: 0.15vw solid #FF9E22;
  border-radius: .3vw;
  padding: 0 .2vw;
}

.firstNumber {
  font-weight: 800;
  font-size: 1.2vw;
}

.secondNumber {
  font-weight: 800;
  font-size: 1.2vw;
}

.secondText {
  font-size: 0.7vw;
  margin: 0;
}

.content {
  padding-left: 1.4vw;
  padding-right: 1.4vw;
}

.content ol {
  font-weight: 500;
  font-size: 1vw;
  padding-left: .7vw;
  max-height: 6vw;
  max-width: 15vw;
  word-break: break-all;
  color: #9FA3BB;
}

.content ol li {
  padding-bottom: .5vw;
}

.userBuilder {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: .5vw;
}

.associations{
  margin: 0;
  color: #55565A;
  font-weight: 600;
  font-size: 1vw;
}

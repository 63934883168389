.content {
  display: flex;
  flex-direction: column;
  margin-top: 5vw;
  align-items: stretch;
  width: 17vw;
}

.content hr {
  height: 1px;
  border: 0;
  border-top: .15vw solid #55565A;
  margin: 2vw 0;
  min-width: 20%;
  text-align: center;
  align-self: center;
}

.image {
  position: absolute;
  /* top: 5vw; */
  top: 10%;
  width: 20vw;
  left: 40%;
}

.content div {
  margin-bottom: 2vw;
}

.login{
  margin: 0 !important;
  display: flex;
  width: 100%;
}

.login h1{
  margin: 0;
  color: #55565A;
  font-size: 1vw;
  text-align: left;
}

.middle{
  margin: 0 !important;
}

.middle p {
  color: #55565A;
  font-size: 1.5vw;
  font-weight: 700;
  opacity: 0.5;
  margin-bottom: 1vw;
}

.middle h1{
  margin: 0;
  font-size: 1.5vw;
  color: #55565A;
}

.complexInput{
  display: flex;
  margin: 0 !important;
  margin-top: 1vw !important;
} 

.codeError{
  position: absolute;
  color: #F46B6B;
  font-size: 1vw;
  margin: 0;
  margin-left: .5vw;
}

.codeInput {
  font-size: 1.5vw;
  outline: 0;
  background: transparent;
  color: black;
  width: 10vw;
  flex-grow: 1;
  border: 0.15vw solid #0096D6;
  border-right: 0;
  border-radius: 0.4vw;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-left: .5vw;
  margin-bottom: 0 !important;
}

.codeInput::placeholder{
  font-size: 1.4vw;
  color: #999999;
}

.userInput {
  font: inherit;
  font-size: 1.2vw;
  border: 0;
  outline: 0;
  background: transparent;
  border: 0.15vw solid #0096D6;
  border-radius: 0.3vw;
  color: black;
  padding: 0.4vw 0.8vw;
  padding-right: 0;
  width: 90%;
}

.userInputError{
  color: #F46B6B;
  border-color: #F46B6B;
}

.userInputError::placeholder{
  color: #F46B6B;
}

.userInputErrorAlert {
  position: absolute;
  color: #F46B6B;
  font-size: 1vw;
  margin: 0;
  margin-left: .5vw;
}

.errorInput {
  color: #e25c5c;
  border-color: #F46B6B;
}

.title{
  font-size: 1.8vw;
  color: #404778;
}

.state{
  font-weight: 700;
  color: #404778;
  padding-top: 1.7vw;
  padding-bottom: .5vw;
  font-size: 1.5vw;
}

.homeButton {
  cursor: pointer;
  background-color: transparent;
  color: #0096D6;
  border: .15vw solid #0096D6;
  border-radius: .4vw;
  padding: 0.7vw;
  font-size: 1.3vw;
  font-weight: bolder;
  line-height: 18px;
  margin-top: 0;
}

.redTitle{
  color: #F46B6B;
  font-weight: 700;
  font-size: 1.9vw;
  padding-bottom: 1.5vw;
}

.smallTitle{
  color: #55565A;
  font-weight: 700;
  font-size: 1.3vw;
  margin: 0;
}

.middleTitle{
  color: #55565A;
  opacity: 0.3;
  font-weight: 700;
  margin: 1.5vw;
}

.flexContainer {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1600px) {
  .image {
    top: 15%;
  }
}

@media only screen and (max-width: 1250px) {
  .image {
    top: 20%;
  }
}
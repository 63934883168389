.yesList {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  border: 0.15vw solid #99d5ef;
  border-radius: 0.4vw;
  margin: 0.6vw 0;
  margin-top: 0;
  padding-left: 0.8vw;
  padding-right: 0.8vw;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  color: #55565a;
  height: 2vw;
}

.yesVotedList {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background-color: #0096d6;
  border: 0.15vw solid #0096d6;
  border-radius: 0.4vw;
  margin: 0.6vw 0;
  margin-top: 0;
  padding-left: 0.8vw;
  padding-right: 0.8vw;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  height: 2vw;
}

.yesList p {
  margin: 0;
  margin-left: 1.5vw;
}

.yesList section {
  display: flex;
  align-items: center;
}

.yesList div {
  display: flex;
  align-items: center;
}

.yesList div p {
  margin: 0;
  margin-left: 0.5vw;
  font-weight: 600;
}

.yesList .box {
  padding: 0.8vw;
  border: 0.15vw solid #99d5ef;
  border-radius: 0.3vw;
}

.yesVotedList p {
  margin: 0;
  margin-left: 1.5vw;
  color: white;
}

.yesVotedList section {
  display: flex;
  align-items: center;
}

.yesVotedList div {
  display: flex;
  align-items: center;
}

.yesVotedList div p {
  margin: 0;
  margin-left: 0.5vw;
  font-weight: 600;
}

.yesVotedList .box {
  padding: 0.1vw;
  border: 0.15vw solid #99d5ef;
  border-radius: 0.3vw;
}

.yesVotedList .box span {
  padding: 0.7vw;
  background-color: transparent;
  border-radius: 0.3vw;
}

.yesVotedList:hover .box span {
  background-color: #99d5ef !important;
}

.yesList:hover {
  background-color: #99d5ef;
}

.yesList:hover .box {
  padding: 0.1vw;
  border: 0.15vw solid white;
  border-radius: 0.3vw;
}

.yesList:hover .box span {
  padding: 0.7vw;
  background-color: white !important;
  border-radius: 0.3vw;
}

/* no button */

.noList {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  border: 0.15vw solid #ffce90;
  border-radius: 0.4vw;
  margin: 0.6vw 0;
  padding-left: 0.8vw;
  padding-right: 0.8vw;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  color: #55565a;
  height: 2vw;
}

.noVotedList {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background-color: #ff9e22;
  border: 0.15vw solid #ff9e22;
  border-radius: 0.4vw;
  margin: 0.6vw 0;
  padding-left: 0.8vw;
  padding-right: 0.8vw;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  height: 2vw;
}

.noList p {
  margin: 0;
  margin-left: 1.5vw;
}

.noList section {
  display: flex;
  align-items: center;
}

.noList div {
  display: flex;
  align-items: center;
}

.noList div p {
  margin: 0;
  margin-left: 0.5vw;
  font-weight: 600;
}

.noList .box {
  padding: 0.8vw;
  border: 0.15vw solid #ffce90;
  border-radius: 0.3vw;
}

.noVotedList p {
  margin: 0;
  margin-left: 1.5vw;
  color: white;
}

.noVotedList section {
  display: flex;
  align-items: center;
}

.noVotedList div {
  display: flex;
  align-items: center;
}

.noVotedList div p {
  margin: 0;
  margin-left: 0.5vw;
  font-weight: 600;
}

.noVotedList .box {
  padding: 0.1vw;
  border: 0.15vw solid #ffce90;
  border-radius: 0.3vw;
}

.noVotedList .box span {
  padding: 0.7vw;
  background-color: transparent;
  border-radius: 0.3vw;
}

.noVotedList:hover .box span {
  background-color: #ffce90 !important;
}

.noList:hover {
  background-color: #ffce90;
}

.noList:hover .box {
  padding: 0.1vw;
  border: 0.15vw solid white;
  border-radius: 0.3vw;
}

.noList:hover .box span {
  padding: 0.7vw;
  background-color: white !important;
  border-radius: 0.3vw;
}

.waitingList {
  display: flex;
  justify-content: space-between;
  color: white;
  font-weight: 400;
  border: 0.15vw solid #404778;
  border-radius: 0.4vw;
  margin: 0.6vw 0;
  margin-bottom: 0;
  padding: 0.1vw 0.8vw;
  background: linear-gradient(#404778, #404778) no-repeat;
  background-position:left;
  background-size: 100% 100%;
  align-items: center;
  height: 2.3vw;
}

.waitingList div {
  display: flex;
  align-items: center;
}

.waitingList p {
  margin: 0;
}

.waitingList div p {
  display: flex;
  align-items: center;
  margin-left: 0.5vw;
  font-weight: 600;
}

.waitingList .box{
  background-color: red;
  width: 100%;
}
.container {
  display: flex;
  position: fixed;
  padding: 1vw;
  top: 0;
  width: 95%;
  justify-content: space-between;
}

.container img {
  width: 7vw;
  cursor: pointer;
}
.card {
  display: flex;
  flex-direction: column;
  background-color: #E5FAFF;
  border: .15vw solid transparent;
  border-radius: .4vw;
  text-align: justify;
  /* height: min-content; */
  width: 100%;
  overflow-y: auto;
}

.content {
  padding-left: 1.4vw;
  padding-right: 1.4vw;
}

.content ol {
  font-weight: 500;
  font-size: 1vw;
  padding: 0;
  display: flex;
  flex-flow: column wrap;
  /* align-content: flex-start; */
  max-height: 5vw;
  /* max-width: 15vw; */
  word-break: break-word;
  color: #9FA3BB;
}

.title{
  display: flex;
  margin-top: 1vw;
  justify-content: space-between;
  align-items: center;
}

.helpContainer {
  background-color: #404778;
  position: absolute;
  left: 66%;
  border: 0.15vw solid transparent;
  border-radius: 0.3vw;
  box-shadow: black 0.1vw 0.1vw 1.2vw;
  padding: 0.5vw;
  z-index: 70;
}

.helpContainer img {
  cursor: pointer;
}

.helpContainer p {
  color: white !important;
  font-weight: 400 !important;
  margin-right: 2vw !important; 
  font-size: .9vw !important;
}

.helpContainer div {
  display: flex;
  justify-content: space-between;
}

.helpContainer ul li {
  color: white;
  font-size: .9vw;
}

.helpContainer ul {
  margin-bottom: 0;
  margin-top: 1.5vw;
  padding-left: 1.5vw;
}

.helpContainer ul li::marker {
  color: white;
}

#item {
  width: 10vw;
  padding-right: 3vw;
  counter-increment: item;
}

.content p {
  margin: 0;
  font-size: 1vw;
  color: #55565A;
  font-weight: 700;
}

.card ol li {
  list-style-type: none;
  cursor: pointer;
}

.card ol li span {
  padding-left: .3vw;
}

.content ol li::before{
  content:counter(item) ".";
  color: #55565A;
}

.content ol li:hover .yesText{
  display: inline-block;
}

.card ol p{
  text-align: end;
  margin: 0;
  margin-bottom: .6vw;
  margin-right: .6vw;
  font-size: .7vw;
  font-weight: 800;
}

.yesText {
  color: #55565A;
  font-size: .9vw;
  padding: 0 !important;
  display:none;
}

.yesText:hover .tooltip{
  display:inline-block;
}

.tooltip {
  display: none;
  text-decoration: none !important;
  border: 0 solid transparent;
  border-radius: .1vw;
  background-color: #404778;
  color: white;
  font-size: .7vw;
  margin-left: .4vw !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 0.1vw 0.2vw !important;
}

.striked {
  color: #FFCE90 !important;
  text-decoration-line: line-through;
}

.striked::before{
  color: #FFCE90 !important;
}

.voting{
  font-weight: bolder;
  color: black !important;
}

.voting::before{
  color: black !important;
}

.valid{
  color:#0096D6 !important;
}

.valid::before{
  color:  #0096D6 !important;
}

.color{
  background-color: red;
}

.tutorialWrapper{
  position: relative;
  height: min-content;
  padding: .7vw;
  background-color: #FFF;
  border: 0.1vw solid transparent;
  border-radius: 0.3vw;
  z-index: 60;
}

@media screen and (min-width: 1919px) {
  #t3 {
    top: 42% !important;
  }
}
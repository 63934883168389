.container {
  margin-bottom: 0 !important;
  margin-right: 1vw;
}

.avatar {
  position: absolute;
  width: 5vw;
  height: 4.5vw;
}

.corner {
  margin-top: 1vw;
  margin-left: 3.9vw;
  align-self: baseline;
  position: relative;
  width: 1.4vw;
  height: 1.4vw;
}

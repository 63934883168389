.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* backdrop-filter: blur(10px); */
  z-index: 20;
  background-color: #0096d6;
  opacity: 0.2;
}

.modal {
  position: absolute;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  top: 25vh;
  left: 5%;
  height: 25vw;
  background-color: white;
  padding: 1.1rem;
  padding-top: 0;
  padding-bottom: .5rem;
  border-radius: 0.2vw;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  align-items: center;
}

.modal h1 {
  font-size: 1.6vw;
  font-weight: 500;
  margin: 0;
  color: #ff9e22;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.modal h1 img {
  margin: 0;
  margin-left: .6vw;
}

.modal section {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.modal section p {
  margin-left: auto;
  color: #bbbbbd;
  font-size: 2.3vw;
  cursor: pointer;
}

.modal button {
  font: inherit;
  font-size: 1vw;
  /* width: fit-content; */
  white-space: nowrap;
  cursor: pointer;
  background-color: #0096d6;
  /* border: 1px solid transparent; */
  border-color: transparent;
  color: white;
  padding: 0.3rem 1vw;
  border-radius: 0.5vw;
  margin-top: 1.5vw;
}

.modal p {
  margin: 0;
  font-size: 1.1vw;
}

.modal div p {
  color: #bbbbbd;
  font-size: 2.5vw;
  cursor: pointer;
}

.modal div {
  max-height: 16vw;
  overflow-y: auto;
  position: relative;
}

.modal div {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.modal div::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.modal img{
  margin: .4vw;
}

.checkmark {
  margin: 0 !important;
  position: absolute;
  margin-left: 3.3vw !important;
  margin-top: 1.2vw !important;
  z-index: 3;
  vertical-align: bottom;
}

@media (min-width: 768px) {
  .modal {
    width: 30rem;
    left: calc(50% - 15rem);
  }
}

@media only screen and (max-width: 1250px) {
  .modal {
    top: 40%;
  }
}

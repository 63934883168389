.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(10px);
  z-index: 100;
  background-color: #e5faff8a;
}

.modal {
  position: fixed;
  top: 30vh;
  left: 5%;
  background-color: white;
  padding: 1.1rem;
  border-radius: 0.5vw;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 110;
  animation: slide-down 300ms ease-out forwards;
  padding-top: 0;
}

.modal h1 {
  font-size: 1.6vw;
  font-weight: 500;
  margin: 0;
  margin-bottom: 1vw;
  color: #ff9e22;
}

.modal button {
  font: inherit;
  font-size: 1.5vw;
  /* width: fit-content; */
  white-space: nowrap;
  cursor: pointer;
  background-color: #0096d6;
  /* border: 1px solid transparent; */
  border-color: transparent;
  color: white;
  padding: 0.3rem 1vw;
  border-radius: 0.5vw;
  margin-top: 1.5vw;
}

.modal p {
  margin: 0;
  font-size: 1.1vw;
}

.modal section {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.modal section p {
  color: #bbbbbd;
  font-size: 2.5vw;
  cursor: pointer;
}

@media (min-width: 768px) {
  .modal {
    /* width: 31%; */
    width: 40rem;
    left: calc(50% - 20rem);
  }
}

@media only screen and (max-width: 1250px) {
  .modal{
    top: 40%;
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.container {
  color: #404778;
  font-weight: 500;
}

.container span {
  font-weight: 700;
}

.title1 {
  margin-bottom: 3vw;
}

.logo {
  position: absolute;
  right: 25%;
  bottom: 78%;
}

.image1 {
  margin-bottom: 11vw;
}

.devices {
  display: flex;
  justify-content: center;
  margin-top: 3vw;
}

.centerDevice {
  margin: 0 3vw;
}

.container {
  display: flex;
  align-items: center;
}

.container p {
  text-align: left;
  margin-left: 0;
  font-size: 1vw;
  color: #55565A;
  font-weight: 400;
}

.container a {
  color: #0096d6;
  font-size: 1.1vw;
  font-weight: 900;
  text-decoration: none;
  padding-left: 1vw;
  font-weight: 400;
}

.container a:hover {
  color: #7db6df;
  text-decoration: none;
}

.container a:active {
  color: #1f6ca3;
  text-decoration: none;
}

.container a:link {
  text-decoration: none;
}

.container a:visited{
  text-decoration: none;
}

container a:active{
  text-decoration: none;
}
.card {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #E5FAFF;
  border-radius: 17px;
  /* margin-top: 2vw; */
}

.striked {
  color: #000000;
  opacity: 0.5;
  text-decoration-line: line-through;
}

.entry{
  display: flex;
  align-items: center;
}

.username{
  margin: 0;
  color: #FF9E22 !important; 
  font-weight: 700;
}

.entry p{
  font-size: 1.3vw;
  margin-left: .5vw;
  color: #55565A;
}

.card h3 {
  color: #55565A;
  overflow-wrap: break-word;
  font-size: 1.5vw;
  margin-left: 1.5vw; 
  margin-right: 1.5vw;
}

.tutorialWrapper{
  position: relative;
  height: min-content;
  padding: .7vw;
  background-color: #FFF;
  border: 0.1vw solid transparent;
  border-radius: 0.3vw;
  z-index: 60;
}
.mainSection {
  display: flex;
  text-align: center;
  flex-flow: row wrap;
  background-color: #e5faff;
  border: 4px solid transparent;
  border-radius: 0.3vw;
  margin-left: 15px;
  margin-right: 15px;
  height: inherit;
}

.sectionPlace {
  display: flex;
  align-items: center;
  border: .15vw solid transparent;
  border-radius: 0.3vw;
  width: 25vw;
  margin: 1vw;
}

.rank1{
  background: #FFD700;
  margin-top: 0;
}

.rank2{
  background: #C0C0C0;
}

.rank2 .place{
  margin-right: 1.2vw;
}

.rank3{
  background: #CD7F32;
}

.rankLow{
  background: #FFFFFF;
}

.blackDot {
  height: 2vw;
  width: 2vw;
  margin-left: -.5vw;
  font-size: 1.3vw;
  background-color: #404778;
  border: .1vw solid white;
  border-radius: 50%;
  display: inline-block;
  color: white;
  text-align: center;
  text-transform: capitalize;
}

.entire {
  display: flex;
  flex-direction: row;
}

hr {
  height: 1px;
  border: 0;
  border-top: .1vw solid #55565A;
  max-width: 95%;
  text-align: center;
  align-self: center;
  margin-top: 0;
}

.points {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
}

.pointsChild {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 1vw;
  text-align: center;
}

.number {
  font-size: 1.3vw !important;
  margin: auto;
  color: #55565A;
  font-weight: 700;
}

.text {
  font-size: 0.8vw !important;
  margin: 0;
  color: #55565A;;
}

.nameText {
  padding-left: 1vw;
  font-size: 1.3vw;
  font-weight: 500;
  color: #55565A;
  font-weight: 700;
}

.place {
  color: rgba(44, 49, 49, 0.8);
  font-size: 1.5vw !important;
  font-weight: 550;
  margin-left: .6vw;
  /* margin: 0; */
}

.voters {
  white-space: break-spaces;
  width: 27vw;
}

.voters img{
  width: 2.3vw !important;
  height: 2.3vw !important;
}

.imageTip:hover .circle{
  display: block;
}

.circle{
  display: none;
  width: 2.1vw;
  height: 2vw;
  padding-bottom: .215vw;
  position: absolute;
  border: #fff solid .15vw;
  border-radius: 50%;
  z-index: 0;
}

.imageWrapper{
  display: flex;
  justify-content: flex-end;
}

.imageTip {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.imageTip:hover .tooltip{
  display: block;
}

.voters p {
  font-size: 1.3vw;
}

.tooltip {
  display: none;
  position: absolute;
  text-decoration: none !important;
  border: 0 solid transparent;
  border-radius: .1vw;
  background-color: #404778;
  color: white;
  font-size: .9vw;
  width: max-content;
  top: 2.9vw;
  text-align: center;
  padding: 0.1vw 0.5vw !important;
  z-index: 10;
  box-shadow:black .15vw .15vw .8vw;
}

.childSection {
  display: flex;
  flex-direction: column;
  padding-left: 1.7vw;
  padding-right: 1.7vw;
}

.childSection h1 {
  margin: 0;
  margin-bottom: 2.5vw;
  margin-top: 1.5vw;
  text-align: left;
  color: #55565a;
}

.childSection section {
  display: flex;
}

.childSection section section {
  flex-direction: column;
  justify-content: normal;
  margin-left: 2vw;
}

.childSection ol {
  margin: 0;
  padding: 1.5vw;
  padding-top: 0;
  max-width: 12vw;
  text-align: left;
  /* font-size: 0.75em; */
  font-size: 1.2vw;
}

.childSection section h1 {
  margin: 0;
  margin-bottom: 1vw;
  white-space: nowrap;
  font-weight: 700;
  font-size: 1.3vw;
}

.childSection section p {
  margin: 0;
  margin-bottom: 1vw;
  font-weight: 400;
  color: #55565A;
  text-align: left;
}

.inputPart{
  display: flex;
  flex-direction: row !important;
  align-items: baseline;
  margin: 0 !important;
  justify-content: space-between !important;
}

p {
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 0;
  margin-top: 0;
}

.user p {
  margin-left: 0.5rem;
}

h1 {
  margin-top: 0;
  margin-bottom: 0.3em;
}

h3 {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}

.timer {
  margin: 0;
  padding: 0.3vw;
  background-color: rgba(80, 179, 71, 0.1);
  border: 1px solid;
  border-color: rgba(80, 179, 71, 0.1);
  border-radius: 0.3vw;
  color: #50B247;
  text-align: center;
  font-weight: 500;
  font-size: .9vw;
  line-height: 30px;
}

.input {
  background-color: white;
  border: 0.12vw solid #0096d6;
  font-size: .9vw;
  color: black;
  font-weight: 700;
  padding: 0.5rem 0.5rem;
  border-radius: 0.3vw;
  min-width: 75%;
  margin-top: 1vw;
  margin-bottom: 2vw;
}

.imageRandomized{
  width: 14vw;
  height: 14vw;
  margin: auto;
}

.imageSection{
  width: 21.5vw;
  height: 21.5vw;
  cursor: pointer;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  border: 0.1vw solid #0096d6;
  border-radius: 0.4vw;
  background-color: #FFF;
}

.tables {
  display: flex;
}

.title {
  position: absolute;
  margin-right: 40vw;
}

.voteCard {
  background-color: #E5FAFF;
  border: 4px solid transparent;
  border-radius: .4vw;
  padding: 1vw;
  padding-top: .6vw;
}

.voteCard img {
  width: 13vw;
  height: 13vw;
  /* margin: auto; */
}

.imageContainer {
  cursor: pointer;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  margin-right: 1.5vw;
  width: 18vw;
  height: 18vw;
  border: 0.1vw solid #0096d6;
  border-radius: 0.4vw;
  background-color: #FFF;
}

.voteCard hr{
  width: 10%;
  margin-top: 1vw;
}

.voteSection{
  display: flex;
  flex-direction: row;
}

.leaderboard{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tipText {
  font-size: .8vw !important;
  max-width: fit-content;
  margin: auto;
  margin-bottom: .3vw;
  color: #55565A;
  word-break: keep-all;
}

.voteButton{
  font: inherit;
  font-size: .9vw;
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;
  background-color: #0096D6;
  border-color: transparent;
  color: white;
  padding: 0.5vw 1.5vw;
  border-radius: .3vw;
  margin-top: 0;
}

.voteButtonDisabled{
  font: inherit;
  font-size: .9vw;
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;
  background-color: #99D5EF;
  border-color: transparent;
  color: white;
  padding: 0.5vw 1.5vw;
  border-radius: .3vw;
  margin-top: 0;
}

.voteSectionTitle{
  margin: 0;
  margin-bottom: 1vw;
  color: #55565A;
  font-weight: 700;
  text-align: left;
}

.halfPart {
  display: flex;
  justify-content: center;
}

.usersCards {
  max-height: 40vw;
  overflow-y: auto;
}

.contentHalfPart {
  display: flex;
  flex-direction: column;
  margin-left: 1vw;
}

.secondHalfPart {
  display: flex;
  flex-direction: row;
  margin-top: 1vw;
  /* width: 100vw; */
}

.votingText {
  text-align: start;
  font-size: 1.5vw;
  font-weight: 900;
  opacity: 0.5;
}

.buttons {
  display: flex;
  flex-direction: column-reverse;
}

.adminButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.panels {
  display: flex;
  flex-direction: row;
}

.ownerPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: 4px solid transparent;
  border-radius: 17px;
  margin-left: 15px;
  margin-right: 15px;
  height: auto;
}

.images {
  position: relative;
}

.images img {
  /* position: absolute; */
  overflow: hidden;
  margin-left: -.7vw;
  margin-bottom: .35vw;
}

.backdrop {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #404778;
  opacity: 0.5;
  z-index: 50;
}

.tutorialWrapper{
  position: relative;
  height: min-content;
  padding: .7vw;
  background-color: #FFF;
  border: 0.1vw solid transparent;
  border-radius: 0.3vw;
  z-index: 60;
}

@media screen and (max-width: 900px) {
  .title {
    font-size: calc(10px + 1vmin);
  }
}

@media screen and (min-width: 1919px) {
  #t1 {
    left: 39% !important;
  }

  #t2{
    left: 33% !important;
  }

  #t4{
    left: 30.5% !important;
  }

  #t5{
    right: 29% !important;
  }
}
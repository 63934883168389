.container {
  display: flex;
  justify-content: center;
}

.tooltip {
  display: none;
  position: absolute;
  text-decoration: none !important;
  border: 0 solid transparent;
  border-radius: 0.1vw;
  background-color: #404778;
  color: white;
  font-size: 0.9vw;
  width: max-content;
  padding: 0.1vw 0.5vw !important;
  z-index: 10;
  box-shadow: black 0.1vw 0.1vw .5vw;
  margin-top: 1.5vw;
}

.container img {
  margin-left: 0.5rem;
  height: 1vw;
}

.container:hover .tooltip {
  display: block;
}

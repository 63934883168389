.container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  bottom: 0;
}

.logo {
  width: 7vw;
}

.container p{
  font-size: 1vw;
  margin: 0;
  margin-right: 1.3vw;
  font-weight: 300;
  color: #BBBBBD;
}

.container {
  color: white;
  background-color: #404778;
  display: flex;
  flex-direction: column;
  position: absolute;
  border: 0.15vw solid transparent;
  border-radius: 0.3vw;
  text-align: justify;
  box-shadow: black 0.1vw 0.1vw 1.2vw;
  padding: 0.5vw;
  z-index: 70;
}

.container p {
  font-weight: 400;
  font-size: 1vw;
  margin: 0;
  padding-bottom: 1vw;
  line-height: normal;
}

.container li::marker{
  color: white;
  font-size: 1.2vw;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.footer p {
  padding: 0;
  color: #999;
}

.footer button {
  margin-right: 1vw;
}

.next {
  color: white;
  font-size: 1vw;
  cursor: pointer;
  background-color: #0096d6;
  border: 0.15vw solid transparent;
  border-radius: 0.2vw;
  padding: 0.2vw 0.7vw;
  margin: 0 !important;
}

.prev {
  color: #00CCFF;
  font-size: 1vw;
  cursor: pointer;
  background-color: transparent;
  border: 0.15vw solid #00CCFF;
  border-radius: 0.3vw;
  padding: 0.2vw 0.7vw;
}

.close {
  color: #0096D6;
  font-size: 1vw;
  cursor: pointer;
  background-color: transparent;
  border: 0;
}
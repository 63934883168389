.button{
  /* font: inherit; */
  font-size: 1.5vw;
  white-space: nowrap;
  cursor: pointer;
  background-color: #0096D6;
  border-color: transparent;
  color: white;
  padding: 0.3vw 0;
  border-radius: .5vw;
  /* margin-left: .5vw; */
  margin-bottom: .5vw;
}

.button2{
  font-size: 1.5vw;
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 0.4vw;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-color: transparent;
  color: white;
  padding: 0.7vw 1.3vw;
  /* border-radius: .1vw; */
}

.buttonDisabled {
  font: inherit;
  font-weight: 700;
  cursor: no-drop;
  background-color:#d1d1d1;
  border: 1px solid transparent;
  color: rgba(64, 71, 120, 0.4);;
  padding: 0.5vw 1.5vw;
  border-radius: 30px;
  margin-left: 10px;
  margin-top: 10px;
}

.button3 {
  font-size: 1.5vw;
  white-space: nowrap;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 0.4vw;
  border-color: transparent;
  color: white;
  padding: .4vw 1.3vw;
  margin-top: 2vw;
}

.logo{
  margin-bottom: 2vw;
}